import request from '@/service/request'

/* 心理咨询室，分页
* @params
* */
export const pagePsychologyRoomApi = (params) => {
  return request.post('/stu/psychologyRoom/page', params)
}

/* 心理咨询室，list
* @params
* */
export const listPsychologyRoomApi = (params = {}) => {
  return request.post('/stu/psychologyRoom/list', params)
}

/* 心理咨询室，根据id获取
* @params
* */
export const getPsychologyRoomByIdApi = (id) => {
  return request.post(`/stu/psychologyRoom/get/${id}`)
}

/* 心理咨询室，删除
* @params
* */
export const delPsychologyRoomByIdApi = (id) => {
  return request.post(`/stu/psychologyRoom/del/${id}`)
}

/* 心理咨询室，保存
* @params
* */
export const savePsychologyRoomApi = (params) => {
  return request.post('/stu/psychologyRoom/save', params)
}
