<!--  心理咨询室  -->
<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['campus','keyword']"
        @on-search="renderTable(1)">
        <el-form-item label="状态" label-width="50px">
          <el-select v-model="queryInfo.enable" size="small">
            <el-option :value="1" label="启用" />
            <el-option :value="0" label="禁用" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-button v-permission="['psycho:room:add']" type="primary" size="small" @click="showDialog()"
        >新增咨询室
        </el-button>
      </div>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="name" label="名字" min-width="200" />
      <el-table-column prop="address" label="地址" min-width="270" />
      <el-table-column prop="campusName" label="校区" width="100" align="center" />
      <el-table-column label="状态" width="180" align="center">
        <template v-slot="{row}">
          <el-switch
            v-permission="['psycho:room:edit']"
            v-model="row.enable"
            @change="handleBatSwitch(row)"
            active-color="var(--color-success)"
            :active-value="1"
            active-text="启用"
            inactive-text="禁用"
            :inactive-value="0"
          />
          <template v-if="!isEditor">
            <el-tag v-if="row.enable" type="success">启用</el-tag>
            <el-tag v-else type="info">禁用</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="170" align="center" fixed="right">
        <template v-slot="{row}">
          <el-button v-permission="['psycho:room:edit']" type="primary" plain size="mini"
                     @click="showDialog(row.id)">编辑
          </el-button>
          <el-button v-permission="['psycho:room:del']"
                     size="mini"
                     type="danger"
                     @click="del(delPsychologyRoomByIdApi, row.id,`心理咨询室-${row.name}`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <psychology-room-dialog ref="dialogRef"
                            :visible.sync="visibleDialog"
                            v-if="visibleDialog"
                            @on-close="renderTable" />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import psychologyRoomDialog from '@/views/pages/studentManage/psychology-room/components/psychologyRoomDialog.vue'
import {
  pagePsychologyRoomApi,
  savePsychologyRoomApi,
  delPsychologyRoomByIdApi
} from '@/api/student-manage/psychology-room-api'

export default {
  name: 'psychologyRoom',
  mixins: [tableView],
  components: {
    psychologyRoomDialog
  },
  data() {
    return {
      isEditor: false,
      queryInfo: {
        enable: null,
        campusId: null
      },
      gradeYearList: []
    }
  },
  async mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('psycho:room:edit')
  },
  methods: {
    delPsychologyRoomByIdApi, // 删除api
    async renderTable(pageNum) {
      await this.getTableData(pagePsychologyRoomApi, pageNum)
    },
    // 启用、禁用
    handleBatSwitch(row) {
      const msg = row.enable ? '启用' : '禁用'
      this.$confirm(`是否确认修改${row.name}的状态为${msg}?`).then(async () => {
        try {
          await savePsychologyRoomApi(row)
          this.$message.success(`${row.name}已${msg}!`)
        } catch (e) {
        }
      }).catch(() => {
        row.enable = row.enable ? 0 : 1
        this.$message.info('已取消')
      })
    }
  }
}
</script>
